/* eslint-disable react/react-in-jsx-scope */
import { Avatar, Breadcrumb, Button, Card, Checkbox, Col, Divider, List, Modal, Radio, Row, Select, Space, Spin, Table, Tabs, Tag, Typography, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

// import VehicleModal from '../../images/vehicleModal.png';
// import DrumModal from '../../images/drumModal.png';
// import ClampingModal from '../../images/clampingModal.png';
// import mapImg from '../../images/mapImg.png';
// import mapImgDeep from '../../images/ManualDeep.png';
// import mapImgMachine from '../../images/JCBMap.png';
// import mapImgTunneling from '../../images/TunnelingMap.png';
// import modalDefault from '../../images/modalDefault.png';
// import modalMan from '../../images/ManWalking.png';
import { LayersControl, MapContainer, Marker, Pane, Polyline, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Context';
import { Icon } from 'leaflet';
import alarmdetails from './reducer';
import TabPane from 'antd/es/tabs/TabPane';
import {ACKNOWLEGEMENT_ALARM, ACKNOWLEGEMENT_ALARM_ERROR, ACKNOWLEGEMENT_ALARM_SUCCESS, REALTIME_ALARM_ERROR, REALTIME_ALARM_LIST, REALTIME_ALARM_SUCCESS, REALTIME_COMMENTS, REALTIME_COMMENTS_ERROR, REALTIME_COMMENTS_SUCCESS, REALTIME_GROUPED_ALARM_ERROR, REALTIME_GROUPED_ALARM_LIST, REALTIME_GROUPED_ALARM_SUCCESS, REALTIME_POST_COMMENTS, REALTIME_POST_COMMENTS_ERROR, REALTIME_POST_COMMENTS_SUCCESS, REALTIME_REGION_ALARM_ERROR, REALTIME_REGION_ALARM_LIST, REALTIME_REGION_ALARM_SUCCESS, REALTIME_RELATED_ALARM_ERROR, REALTIME_RELATED_ALARM_LIST, REALTIME_RELATED_ALARM_SUCCESS, RESOLUTION_ALARM, RESOLUTION_ALARM_ERROR, RESOLUTION_ALARM_SUCCESS } from './constants';
import axios from 'axios';
import TextArea from 'antd/es/input/TextArea';
// import ImgCrop from 'antd-img-crop';
import { Option } from 'antd/es/mentions';
import eventsicons from '../eventstypes';
import { FullscreenOutlined,FullscreenExitOutlined, HomeOutlined, DownloadOutlined } from '@ant-design/icons';
import { datetimeCovertertolocal } from '../datetime-converter';
import GoogleMap from '../Map/map';
import TLPLogs from './tlp-logs';
// import {saveAs} from 'file-saver';

import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

import Papa from 'papaparse';
import Plot from 'react-plotly.js';
import leak from '../WellVisualization/LeakDetection/leak_coordinates.csv';


// const linecolor = { color: 'green' };
const pipecolor = { color: 'black' };
const odmetercolor = { color: '#036CD6' };
const optionalcolor ={color:'orange'};
const bordercolor = { color: '#fc8383'};
const pidsicon = new Icon({
	// iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
	iconUrl:require('../../images/Refinery1.png'),
	iconSize: [30, 30],
	iconAnchor:[18, 30]
});


// const markericon = new Icon({
// 	iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
// 	// iconUrl:require("./location-pin.png"),
// 	iconSize:[45,50],
// 	iconAnchor:  [25, 50]});
const CHicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/ch.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const WBicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/wb.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const KMPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/kmp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const TLPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/tlp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const TPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/tp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});

const defaulticon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/markerimages/default-white.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});

const wellicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/oil-well.png'),
	iconSize: [40, 40],
	iconAnchor:[18, 36]
});

let urls=[];
let resurls=[];

export default function AlarmDetails() {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const { state } = useLocation();
	const navigate = useNavigate();
	const {handleGetMapdata, mapdata, getAllEvents, eventsData, optionalpipedata,handleOptionalPipelinedata, getRegion, regiondata, getPids, pidsData, wellinfo, getWellInfo} = useAuth();
	const [size, setSize] = useState('200px');
	const initalState = {grouped_alarmdata_details:[], related_alarmdata_details:[], region_alarmdata_details:[], region_comment_details:[], comment_post_details:[],
		acknowlegement_alarm_details:[], resolution_details:[],relatimealarmdata:null,
		details: null, pending: false, error: false };
	const [alarmdata, dispatch] = useReducer(alarmdetails, initalState);
	const [comments, setComment] = useState(null);
	const userid = localStorage.getItem(`${dashboardtype.toUpperCase()}_USER_ID`);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [modelAttachmentOpen, setAttachment] = useState(false);
	const [modelResolutionOpen, setResolution] = useState(false);
	const [openCommentModal, setOpenCommentModal] = useState(false);

	const [wellId, setWellId] = useState({id:null,name:''});
	const [wellModal, setWellModal] = useState(false);
	const [wellCoordinatesurl, setWellCoordinatesurl] = useState(null);	
	const [meshData, setMeshData] = useState({ x: [], y: [], z: [], color: [] });
	const [leakData, setLeakData] = useState({ x: [], y: [], z: [] });
	const [meshSpin, setMeshSpin] = useState(false);	

	const [tab, setTab] = useState(1);
	const [openAttachmentModal, setShowAttachmentsModal] = useState(false);
	const [openResAttachmentModal, setShowResAttachmentsModal] = useState(false);

	const allPidsData = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_PIDS`));
	const envtlpEnable = process.env.REACT_APP_TLP == undefined || process.env.REACT_APP_TLP == 'undefined' ? 'disabled' : process.env.REACT_APP_TLP;
	const tlpEnable = localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_TLP`) == 'null' ? envtlpEnable : localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_TLP`);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const oneviewType = process.env.REACT_APP_ONEVIEW_TYPE ? process.env.REACT_APP_ONEVIEW_TYPE : 'pipeline';
	const oneviewtypeLabel = oneviewType &&  oneviewType.toLowerCase() == 'pipeline' ? langdata && langdata.Pipeline : oneviewType.toLowerCase() == 'airport' ? langdata && langdata.Airport : oneviewType.toLowerCase() == 'border' ? langdata && langdata.Border : 'pipeline';

	let groupedAlarmList = [];

	alarmdata.grouped_alarmdata_details && alarmdata.grouped_alarmdata_details.map((item) => {
		const datetime = datetimeCovertertolocal(item.datetime);
		const newdate = datetime.localdate + ' ' +  datetime.localtime;
		groupedAlarmList.push({
			key: item.alarm_prm_id,
			alarmId: item.alarm_prm_id,
			severity:item.alarmSeverity && item.alarmSeverity==3 ? 'RED' :  item.alarmSeverity==2 ? 'AMBER' : item.alarmSeverity==1 ? 'GREEN' : item.alarmSeverity==0 ? 'CLEAR' : '',
			activity: item.eventType,
			description:item.ruleDesc,
			date:newdate,
			duration:item.duration ? (item.duration/60).toFixed(2) + ` ${langdata && langdata.Min ? langdata.Min:' Min'}` : 0 + ` ${langdata && langdata.Min ? langdata.Min:' Min'}`,
			channel: item.channel_name,
			chainage: item.locationDetails ? item.locationDetails.info : null,
			locationDetails:item.locationDetails && item.locationDetails,
			alarmSeverity:item.alarmSeverity,
			pidsname:pidsData && pidsData.map((pids) => { if (item.pidsinfoId === pids.id){ return pids.name.toUpperCase();} }),
			region : regiondata && regiondata.map((region) => { if (item.regioninfoId[0] === region.id){ return region.regionName.toUpperCase();} }),
		});
	});


	let relatedAlarmList = [];

	alarmdata.related_alarmdata_details && alarmdata.related_alarmdata_details.map((item) => {
		const datetime = datetimeCovertertolocal(item.datetime);
		const newdate = datetime.localdate + ' ' +  datetime.localtime;
		relatedAlarmList.push({
			key: item.alarm_prm_id,
			alarmId: item.alarm_prm_id,
			severity:item.alarmSeverity && item.alarmSeverity==3 ? 'RED' :  item.alarmSeverity==2 ? 'AMBER' : item.alarmSeverity==1 ? 'GREEN' : item.alarmSeverity==0 ? 'CLEAR' : '',
			activity: item.eventType,
			description:item.ruleDesc,
			date:newdate,
			duration:item.duration ? (item.duration/60).toFixed(2) + ` ${langdata && langdata.Min ? langdata.Min:' Min'}` : 0 + ` ${langdata && langdata.Min ? langdata.Min:' Min'}`,
			channel: item.channel_name,
			chainage: item.locationDetails ? item.locationDetails.info : null,
			locationDetails:item.locationDetails && item.locationDetails,
			alarmSeverity:item.alarmSeverity,
			pidsname:pidsData && pidsData.map((pids) => { if (item.pidsinfoId === pids.id){ return pids.name.toUpperCase();} }),
			region : regiondata && regiondata.map((region) => { if (item.regioninfoId === region.id){ return region.regionName.toUpperCase();} }),
		});
	});

	let regionAlarmList = [];

	alarmdata.region_alarmdata_details && alarmdata.region_alarmdata_details.map((item) => {
		const datetime = datetimeCovertertolocal(item.datetime);
		const newdate = datetime.localdate + ' ' +  datetime.localtime;
		regionAlarmList.push({
			key: item.alarm_prm_id,
			alarmId: item.alarm_prm_id,
			severity:item.alarmSeverity && item.alarmSeverity==3 ? 'RED' :  item.alarmSeverity==2 ? 'AMBER' : item.alarmSeverity==1 ? 'GREEN' : item.alarmSeverity==0 ? 'CLEAR' : '',
			activity: item.eventType,
			description:item.ruleDesc,
			date:newdate,
			duration:item.duration ? (item.duration/60).toFixed(2) + ` ${langdata && langdata.Min ? langdata.Min:' Min'}` : 0 + ` ${langdata && langdata.Min ? langdata.Min:' Min'}`,
			channel: item.channel_name,
			chainage: item.locationDetails ? item.locationDetails.info : null,
			locationDetails:item.locationDetails && item.locationDetails,
			alarmSeverity:item.alarmSeverity,
			pidsname:pidsData && pidsData.map((pids) => { if (item.pidsinfoId === pids.id){ return pids.name.toUpperCase();} }),
			region : regiondata && regiondata.map((region) => { if (item.regioninfoId === region.id){ return region.regionName.toUpperCase();} }),

		});
	});

	let commentdetails = [];

	alarmdata.region_comment_details && alarmdata.region_comment_details.map((item) => {
		const datetime = datetimeCovertertolocal(item.createdAt);
		const newdate = datetime.localdate + ' ' +  datetime.localtime;
		commentdetails.push({
			key: item.id,
			userId: item.userId,
			permissions:item.permissions,
			commentDate: newdate,
			commentTime:item.commentTime,
			comments:item.comments,
			title:item.username + ' (' + item.permissions + ')' + `${langdata.on || langdata.on=='' ? langdata.on : ' on '}` + newdate,

		});
	});

	let allPidsDatas =[];

	if(allPidsData.length>0) {
		allPidsData && allPidsData.map((item)=>{
			allPidsDatas.push({
				geocode:[item.latitude, item.longitude], 
				pop:item.label
			});
		});
	
	}

	function callback(key) {
		setTab(key);
		if(key == 4){
			dispatch({ type : REALTIME_GROUPED_ALARM_LIST});
			let data = JSON.stringify({
				id: state.data.alarm_prm_id,
				pidsinfoId: state.data.pidsinfoId,
				regioninfoId:null,
				allocationId: null
			});

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/groupedalarm`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};
      
			axios.request(config)
				.then((response) => {
					if (response.status === 200) {
						dispatch({ type : REALTIME_GROUPED_ALARM_SUCCESS, payload : response.data});
					}else {
						dispatch({ type : REALTIME_GROUPED_ALARM_ERROR});
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : REALTIME_GROUPED_ALARM_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});

		}
		if(key==3){
      

			dispatch({ type : REALTIME_RELATED_ALARM_LIST});
			let data = JSON.stringify({
				id: state.data.alarm_prm_id,
				pidsinfoId: state.data.pidsinfoId,
				regioninfoId:null,
				allocationId: null,
        
			});

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/relatedalarm`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};
      
			axios.request(config)
				.then((response) => {
					if (response.status === 200) {
						dispatch({ type : REALTIME_RELATED_ALARM_SUCCESS, payload : response.data});
					}else {
						dispatch({ type : REALTIME_RELATED_ALARM_ERROR});
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : REALTIME_RELATED_ALARM_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});

		}


		if(key==5){

			dispatch({ type : REALTIME_REGION_ALARM_LIST});
			let data = JSON.stringify({
				id: state.data.alarm_prm_id,
				regioninfoId:null,
				allocationId: null,
				eventchannel:'all',
				pipelineConfigId:state.data.pipelineConfigId
			});

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/alarm`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};
      
			axios.request(config)
				.then((response) => {
					if (response.status === 200) {
						dispatch({ type : REALTIME_REGION_ALARM_SUCCESS, payload : response.data.data});
					}else {
						dispatch({ type : REALTIME_REGION_ALARM_ERROR});
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : REALTIME_REGION_ALARM_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});

		}


		if(key==2){

			getCommentData();

		}


		if(key==1){

			dispatch({ type : REALTIME_ALARM_LIST});
			let data = JSON.stringify({
				allocationId:null,
				pidsinfoId: state.data.pidsinfoId,
				id:state.data.alarm_prm_id
			});

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/alarmsearch`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};
      
			axios.request(config)
				.then((response) => {
					if (response.status === 201) {
						dispatch({ type : REALTIME_ALARM_SUCCESS, payload : response.data.data});
					}else {
						dispatch({ type : REALTIME_ALARM_ERROR});
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : REALTIME_ALARM_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});

		}


	}

	const getCommentData = () =>{

		dispatch({ type : REALTIME_COMMENTS});
    
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/comments?alarmId=${state.data.alarm_prm_id}&pidsinfoId=${state.data.pidsinfoId}&$sort[createdAt]=-1`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : ''
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : REALTIME_COMMENTS_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : REALTIME_COMMENTS_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : REALTIME_COMMENTS_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});
	};

	const columns = [

		{
			title: `${langdata && langdata.AlarmId ? langdata.AlarmId:'Alarm Id'}`,
			dataIndex: 'alarmId',
			key: 'alarmId',
			render: (text, record) => (
				<Space size="middle">
					{ record  && 
			<span>
				<span style={{ float: 'left', backgroundColor: record.alarmSeverity==3 ? 'red' :
					record.alarmSeverity==2 ? 'gold' : record.alarmSeverity==1 ? 'green' : record.alarmSeverity==0 ? 'violet' : '', 
				width: '3px', height: '40px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} />
			</span>
					}
					<text>{text}</text>
				</Space>
			)
		},
      
		{
			title: `${langdata && langdata.AlarmSeverity ? langdata.AlarmSeverity:'Alarm Severity'}`,
			dataIndex: 'severity',
			key: 'severity',

			render: (_, { severity }) => (
				<>
					<Tag color={severity === 'RED' ?'red' :  severity === 'GREEN' ?'green' : severity === 'AMBER' ? 'gold' : severity === 'CLEAR' ? 'violet' :'' } key={severity}>
						{severity}
					</Tag>
				</>
			),
		},
      
      
		{
			title: `${langdata && langdata.Activity ? langdata.Activity:'Activity'}`,
			dataIndex: 'activity',
			key: 'activity',

		},
		{
			title: `${langdata && langdata.Region ? langdata.Region:'Region'}`,
			dataIndex: 'region',
			key: 'region',
		},
		{
			title: `${langdata && langdata.PIDS ? langdata.PIDS:'PIDS'}`,
			dataIndex: 'pidsname',
			key: 'pidsname',
			
		},
		{
			title: `${langdata && langdata.Channel ? langdata.Channel:'Channel'}`,
			dataIndex: 'channel',
			key: 'channel',
		},
		{
			title: `${langdata && langdata.Description ? langdata.Description:'Description'}`,
			dataIndex: 'description',
			key: 'description',
		},
		
		{
			title: `${langdata && langdata.Date ? langdata.Date:'Date'}`,
			dataIndex: 'date',
			key: 'date',
			// render: (_, date) => (
			// 	<Space size="middle">
			// 		<p title="Edit Alarm">{ new Date(date.date).toLocaleDateString('en-UK', {
			// 			day: '2-digit',
			// 			month: '2-digit',
			// 			year: 'numeric'
			// 		})}</p>
			// 	</Space>
			// )
		},
   
		
		{
			title: `${langdata && langdata.Chainage ? langdata.Chainage:'Chainage'}`,
			dataIndex: 'chainage',
			key: 'chainage',
		}
	];


	const pipeline = [];
	const center=[];
	const odmeter=[];
	const optionalpath = [];
	const markers = [];
	// const points = [];
	// const pidsmarker=[];
	// const line = [];
	const KMP = [];
	const WB = [];
	const Turn = [];
	const TLP = [];
	const map_type= localStorage.getItem(`${dashboardtype.toUpperCase()}_MAP_TYPE`);

	center.push(state.data && state.data.locationDetails && state.data.locationDetails.Lat, state.data && state.data.locationDetails && state.data.locationDetails.Long);
	mapdata &&  mapdata.map((item)=>{
		// if(index==0){
		// 	center.push(item.latitude,item.longitude);
		// }
		pipeline.push([item.latitude,item.longitude]);
	});

	mapdata &&  mapdata.map((item)=>{

		odmeter.push([item.fibreLatitude,item.fibreLongitude]);
	});
  
	optionalpipedata &&  optionalpipedata.map((item)=>{

		optionalpath.push([item.walkingLatitude,item.walkingLongitude]);
	});

	const tempMapArray = {};
	const teampOptionalMapArray = {};
	const wells = [];

	mapdata && mapdata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!tempMapArray[fieldValue]) {
			tempMapArray[fieldValue] = [];
		}
		tempMapArray[fieldValue].push(item);
	});

	optionalpipedata && optionalpipedata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!teampOptionalMapArray[fieldValue]) {
			teampOptionalMapArray[fieldValue] = [];
		}
		teampOptionalMapArray[fieldValue].push(item);
	});

	mapdata &&  mapdata.map((item)=>{

		if(item.rouMarker=='CH'){
			markers.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.CH ? langdata.CH:'CH'}`
			},);
		}
		else if(item.rouMarker=='WB'){
			WB.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.WB ? langdata.WB:'WB'}`
			},);
		}

		else if(item.rouMarker=='TLP'){
			TLP.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.TLP ? langdata.TLP:'TLP'}`
			},);
		}

		else if(item.rouMarker=='KMP'){
			KMP.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.KMP ? langdata.KMP:'KMP'}`
			},);
		}

		else if(item.rouMarker=='TP'){
			Turn.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.TP ? langdata.TP:'TP'}`
			},);
		}


	});

	wellinfo && wellinfo.map((item) => {
		if(item.latitude && item.longitude){			
			wells.push({
				id:item.id,
				geocode:[item.latitude,item.longitude],
				pop: item.name.toUpperCase()
			},);	
		}
	});

	const alarm_markers= [];

	// const alarmmap = tab && tab==1 ? [state.data] : tab==3 ? relatedAlarmList : tab==4 ? groupedAlarmList : tab == 5 ? regionAlarmList :[state.data] 
	const alarmmap =[state && state.data]; 

	eventsicons && eventsicons.map((icondata)=>{
		if(alarmmap.length > 0) {
			alarmmap && alarmmap.map((item)=>{
				if(item.activity || item.eventType ==icondata.type){
					alarm_markers.push({
						geocode:[item.locationDetails.Lat,item.locationDetails.Long],
						pop:item.activity ? item.activity : item.eventType,
						alarmSeverity:item.alarmSeverity,
						// eslint-disable-next-line no-undef
						ic: new L.icon({
							iconUrl: icondata.severity[item.alarmSeverity],
							iconSize: [30, 40],
							iconAnchor:[18, 36]
						})
					},);
				}
			});
		}
	});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	
	useEffect(() => {
		if(!state) {
			navigate(-1);
		}
	},[authtoken]);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		getAllEvents(authtoken);
		if(map_type && map_type === 'leaflet' && state) {
			handleGetMapdata(authtoken,state.data.regioninfoId,null,null);
			handleOptionalPipelinedata(authtoken,state.data.pipelineId,state.data.regioninfoId,null,null);
		}
		dispatch({ type : REALTIME_ALARM_LIST});
		getAlarm();
		getRegion(authtoken);
		getPids(authtoken);      
		if(oneviewType.toLowerCase() == 'well'){
			getWellInfo(authtoken);
		}
	},[authtoken,tab]);

	const getAlarm = () =>{
		let data = JSON.stringify({
			allocationId:null,
			pidsinfoId: state && state.data.pidsinfoId,
			id : state && state.data.alarm_prm_id
		});

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/alarmsearch`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 201) {
					dispatch({ type : REALTIME_ALARM_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : REALTIME_ALARM_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : REALTIME_ALARM_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});
	};


	const handleComment= (e)=>{
		setComment(e.target.value);
	};

	const handleAckComment= (e)=>{
		setAckRemarks(e.target.value);
	};


	const handleResComment= (e)=>{
		setResRemarks(e.target.value);
	};

	const handleCommentSubmit =()=>{

		if(comments){
			dispatch({ type : REALTIME_POST_COMMENTS});

			let data={
				alarmId: state.data.alarm_prm_id,
				permissions: permission && permission,
				userId: userid && userid,
				comments: comments && comments,
				commentTime: moment.utc().format('LTS'),
				commentDate: moment.utc().format('YYYY-MM-DD'),
				pidsinfoId:state.data.pidsinfoId ? state.data.pidsinfoId : null
			};
  
			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/comments`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};
      
			axios.request(config)
				.then((response) => {
					if (response.status === 201) {
						Modal.success({ title: `${langdata && langdata.Commentaddedsuccessfully ? langdata.Commentaddedsuccessfully :'Comment added successfully'}` });
						getCommentData();
						setComment();   
						dispatch({ type : REALTIME_POST_COMMENTS_SUCCESS, payload : response.data});
					}else {
						dispatch({ type : REALTIME_POST_COMMENTS_ERROR});
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : REALTIME_POST_COMMENTS_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});

		}
		setOpenCommentModal(false);


	};

	// acknowlegment starts
	const radioStyle = {
		display: 'flex',
		height: '30px',
		lineHeight: '30px'
	};

	let showOtherOpt = false;

	if (eventsData !== null) {
		var events =eventsData && eventsData.map((item) => (
			<Radio style={radioStyle} key={item.id} value={item.eventType}>
				{item.eventType}
			</Radio>
		));
	}

	const [ackRemarks, setAckRemarks] = useState('');
	const [ackvalue, setAckValue] = useState(1);
	const [eventname, setEventName] = useState(null);
	const onAckChange = (e) => {
		setAckValue(e.target.value);
	};

	const onChangeEvent = (e) => {
		setEventName(e.target.value);
	};


	const handleModelSave = ()=>{
    
		dispatch({type : ACKNOWLEGEMENT_ALARM});

		let data= JSON.stringify({
			actualEvent: state.data.eventType,
			alarmId:state.data.alarm_prm_id,
			remarks: ackRemarks ? ackRemarks.replace(/'/g, '"') : '',
			imageUrl: urls && urls.length > 0 ? urls : [],
			channelId:state.data.channelId,
			pidsinfoId: state.data.pidsinfoId,
			predictedEvent: ackvalue && ackvalue===1 ? state.data.eventType : eventname,
			userId: localStorage.getItem(`${dashboardtype.toUpperCase()}_USER_ID`)
		});

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/acknowledgement`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 201) {
					dispatch({ type : ACKNOWLEGEMENT_ALARM_SUCCESS, payload : response.data.data});
					Modal.success({ title: `${langdata && langdata.Acknowledgementaddedsuccessfully ? langdata.Acknowledgementaddedsuccessfully :'Acknowledgement added successfully'}` });
					getAlarm();       
					setAttachment(false);
					urls=[];
					data = response.data;
				}else {
					dispatch({ type : ACKNOWLEGEMENT_ALARM_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : ACKNOWLEGEMENT_ALARM_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});

	};

	const handleModelCancel = ()=>{
		urls=[];
		setAttachment(false);
		setWellModal(false);

	};

	const handleAttachmentModelOpen = ()=>{
		urls=[];
		setAttachment(true);
	};

	// image upload starts for acknowlegement


	// const [fileList, setFileList] = useState([]);
 
   
	const onChangeImage = (event) => {
		const file = event.target.files[0];
		const formData = new FormData();
		formData.append('file',file && file ? file : '');
		// setFileList(newFileList);
 
		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/fileattachment`,
			timeout: timeout,
			headers: { 
				// 'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : formData
		};
     
		axios.request(config)
			.then((response) => {
				if (response.status === 201) {
					// setimageUrls(response.data.fileUrl);
					if(modelAttachmentOpen==true){

						urls.push(response.data.fileUrl);
					}
					else{
						resurls.push(response.data.fileUrl);
					}
					Modal.success({ title: `${langdata && langdata.Fileuploadedsuccessfully ? langdata.Fileuploadedsuccessfully :'File uploaded successfully'}` });
					// dispatch({ type : ACKNOWLEGEMENT_ALARM_FILE_UPLOAD_SUCCESS, payload : response.data});
					// data = response.data
				}else {
					// dispatch({ type : ACKNOWLEGEMENT_ALARM_FILE_UPLOAD_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
				// dispatch({ type : ACKNOWLEGEMENT_ALARM_FILE_UPLOAD_ERROR});
			});
 
 
	};
	// const onPreview = async (file) => {
	// 	let src = file.url;
	// 	if (!src) {
	// 		src = await new Promise((resolve) => {
	// 			const reader = new FileReader();
	// 			reader.readAsDataURL(file.originFileObj);
	// 			reader.onload = () => resolve(reader.result);
	// 		});
	// 	}
	// 	const image = new Image();
	// 	image.src = src;
	// 	const imgWindow = window.open(src);
	// 	imgWindow?.document.write(image.outerHTML);
	// };
 
 
	// image upload ends


  
	// acknowlegment ends


	// resolution starts

	const [retraincheck, setRetrainCheck] = useState(false);
	const [resvalue, setResValue] = useState(1);
	const [resRemarks, setResRemarks] = useState('');
	const onResChange = (e) => {
		setResValue(e.target.value);
	};


	const handleResModelOpen = ()=>{
		resurls=[];
		setResolution(true);
	};


	const handleResModelSave = ()=>{
		dispatch({ type : RESOLUTION_ALARM});

		let data={
			alarmId:state.data.alarm_prm_id,
			predictedEvent: resvalue===1 ? state.data.eventType : eventname,
			comments: state.data.comment,
			isEnabledForRetraining: retraincheck,
			// isReTrainingCompleted: null,
			imageUrl: resurls && resurls.length > 0 ? resurls : [],
			reTrainingDate: moment()
				.utc()
				.format(),
			pidsinfoId: state.data.pidsinfoId,
			remarks: resRemarks.replace(/'/g, '"'),
			actualEvent: state.data.eventType,
			userId: localStorage.getItem(`${dashboardtype.toUpperCase()}_USER_ID`),
			isSynced: retraincheck
		};

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/resolution`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 201) {
					dispatch({ type : RESOLUTION_ALARM_SUCCESS, payload : response.data});
					Modal.success({ title: `${langdata && langdata.Resolutionaddedsuccessfully ? langdata.Resolutionaddedsuccessfully :'Resolution added successfully'}` });
					getAlarm();     
					setResolution(false);
					resurls=[];

				}else {
					dispatch({ type : RESOLUTION_ALARM_ERROR});
					Modal.warning({ title: langdata && langdata.Unabletoadd ? langdata.Unabletoadd : 'Unable to add.' });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : RESOLUTION_ALARM_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}else{
					Modal.warning({ title: langdata && langdata.Unabletoadd ? langdata.Unabletoadd : 'Unable to add.' });
				}
			});


	};

	const handleResModelCancel = ()=>{
		resurls =[];
		setResolution(false);
	};

	const onCheck = (e) => {
		setRetrainCheck(e.target.checked); 
	};


	// resolution ends

	const openShowAttachments = () => {
		setShowAttachmentsModal(true);
	};

	const openShowResAttachments = () => {
		setShowResAttachmentsModal(true);
	};

	const closeShowAttachments = () => {
		setShowAttachmentsModal(false);
		setShowResAttachmentsModal(false);
	};


	// const handleAttachmentDialog = (url, index) => {

	// // 	let url1 = url;
	// // 	saveAs(url1, 'Attachment' + index, {autoBom: true});

	// // };

	const handleDownload = (imageUrl) => {
		fetch(imageUrl)
			.then(response => response.blob())
			.then(blob => {
			// Extract filename from URL
				const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', filename);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch(error => console.error('Error downloading image:', error));
	};
	

	const handleOpenCommentBox = () =>{
		setOpenCommentModal(true);
	};

	const handleCloseCommentBox = () =>{
		setOpenCommentModal(false);
	};

	const daysDifference = (new Date(
		new Date().getUTCFullYear(),
		new Date().getUTCMonth(),
		new Date().getUTCDate(),
		new Date().getUTCHours(),
		new Date().getUTCMinutes(),
		new Date().getUTCSeconds(),
	).getTime() - new Date(state && state.data && state.data.datetime).getTime())/ (1000 * 60 * 60 * 24);

	const mapKey = (value, type,name) => {
		if(type=='well'){
			setWellModal(true);
			setWellId({id:value, name:name});
		}

	};

	useEffect(() => {
		if(wellId.id){

			getWellCoordinates();
		}

		// setWellId(tempWellId);
		// setSocketUrl(tempurl);

	},[wellId]);

	const getWellCoordinates =()=>{		

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellcooridnatesdata?wellId=${wellId.id}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					
					setWellCoordinatesurl(response.data.url);
				}else{
					
					setWellCoordinatesurl(null);
				}
			})
			.catch((error) => {
				setWellCoordinatesurl(null);
				
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});
	};

	useEffect(() => {
		setMeshSpin(true);
		
		if(wellCoordinatesurl){

		
			// Load cylinder mesh coordinates with Jet colors
			Papa.parse(wellCoordinatesurl, {
				download: true,
				header: true,
				complete: (results) => {
					const data = results.data;
					setMeshData({
						x: data.map(row => parseFloat(row.x)),
						y: data.map(row => parseFloat(row.y)),
						z: data.map(row => parseFloat(row.z)),
						color: data.map(row => `rgb(${Math.round(parseFloat(row.r) * 255)}, ${Math.round(parseFloat(row.g) * 255)}, ${Math.round(parseFloat(row.b) * 255)})`)
					});
					setMeshSpin(false);
				}
			});
			Papa.parse(leak, {
				download: true,
				header: true,
				complete: (results) => {
					const data = results.data;
					setLeakData({
						x: data.map(row => parseFloat(row.x)),
						y: data.map(row => parseFloat(row.y)),
						z: data.map(row => parseFloat(row.z)),
					});
				}
			});
		}else{
			setMeshSpin(false);
			setMeshData({});
		}
	}, [wellCoordinatesurl,wellId]);

	const numPointsPerCircle = 100;
	const numCircles = 100;
	const i = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => Math.floor(index / numPointsPerCircle) * numPointsPerCircle + index % numPointsPerCircle);
	const j = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => Math.floor(index / numPointsPerCircle) * numPointsPerCircle + (index % numPointsPerCircle + 1) % numPointsPerCircle);
	const k = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => i[index] + numPointsPerCircle);
	// const l = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => j[index] + numPointsPerCircle);

	const wellData = [
		{
			type: 'mesh3d',
			x: meshData.x,
			y: meshData.y,
			z: meshData.z,
			i: i,
			j: j,
			k: k,
			facecolor: meshData.color,  // Apply color
			colorscale: 'Jet',  // Ensure this is the correct scale for your color data
			showscale: true,
			hoverinfo: 'none',  // Customize the hover info displayed
			text:meshData &&  meshData.z && meshData.z.map(Math.floor),
			// hoverlabel: {
			// 	bgcolor: meshData.color,  // Background color of the tooltip
			// 	font: {
			// 		color: 'white'  // Text color in the tooltip
			// 	}
			// },
			hovertemplate : 'Depth : %{text}<extra></extra>',
			// reversescale:true
		},
		{
			type: 'scatter3d',
			mode: 'markers',
			x: leakData.x,
			y: leakData.y,
			z: leakData.z,
			marker: { size: 5, color: 'red' },
			name: 'Leak Detection',
			hovertemplate : 'Leak Depth : %{z}<extra></extra>',
		}
		
		// {
		//   type: 'scatter3d',
		//   mode: 'markers',
		//   x: leakData.x,
		//   y: leakData.y,
		//   z: leakData.z,
		//   marker: { size: 5, color: 'red' },
		//   name: 'Leak Detection'
		// }
	];

	const wellLayout = {
		scene: {
			xaxis: { title: 'X' },
			yaxis: { title: 'Y' },
			zaxis: { title: 'Depth (m)', 
				autorange: 'reversed',
				tickmode: 'auto', tickvals:  'auto',
				// dtick : depthRangeFrom && depthRangeTo ? (depthRangeFrom - depthRangeTo)/10 : null,
				// nticks : 10,
				// ticktext: ['3000 m', '2000 m', '1000 m', '0 m'],
				// range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ 3000 , 0 ],
			},

			camera:{
				
				center: {
					'x': 0,
					'y': 0,
					'z': 0
				},
				eye: {
					'x': 1.4,
					'y': 1,
					'z': 0
				},
				up: {
					'x': 0,
					'y': 0,
					'z': 1
				}

			}
		},
		title: {
			text: wellId && wellId.name,
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area
	
	};

	const wellKey = (value) => {		
		setWellId({id:value.id, name:value.pop});
		setWellModal(true);
	};

	return (
		<>
			{state ?
				<>
					{/* <Row style={{justifyContent:'right', display:'flex'}}>

				<Button type='primary' onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} style={{marginRight:'20px'}} >View Map</Button>
			</Row> */}
					{size && size === '200px' ? 
						<Content>

							{/* map filter starts  */}

							{/* map filter ends */}
							<Row gutter={{
								xs: 8,
								sm: 16,
								md: 24,
								lg: 32,
							}} style={{margin:'10px'}} >

								<Col className="gutter-row" span={24}>
      

									<Card style={{minHeight:'400px'}}>
										<Breadcrumb
											items={[
												{
													href: '/dashboard',
													title: <HomeOutlined />,
												},
												{
													title: ( state.path === 'realtime-alarm' ? <a onClick={()=>{navigate(-1);}}>{langdata && langdata.RealtimeAlarms ? langdata.RealtimeAlarms:'Realtime Alarms'}</a> : state.path === 'alarm' ? <a onClick={() => {navigate(-1);}} >{langdata && langdata.AlarmsReport ? langdata.AlarmsReport:'Alarms Report'}</a> : state.path === 'alarm-analytics' ? <a onClick={() => {navigate(-1);}} >{langdata && langdata.AlarmsAnalytics ? langdata.AlarmsAnalytics:'Alarms Analytics'}</a> : <a onClick={() => {navigate(-1);}} >{langdata && langdata.RetrainingData ? langdata.RetrainingData:'Retraining Data'}</a> )
												},
												{
													title: `${langdata && langdata.AlarmDetails ? langdata.AlarmDetails:'Alarm Details'}`,
												},
											]}
											style={{ marginBottom : '10px'}}
										/>
										{/* map starts */}
										{map_type && map_type !== 'leaflet' ?
				
											<GoogleMap alarmDetailsData = {state.data} height = {'30vh'} regionId={state.data.regioninfoId} onClick={mapKey} />
											:
											mapdata && mapdata ? 
												<div className="App" style={{textAlign: 'left'}}>
        
													<MapContainer center={center} zoom={17} scrollWheelZoom={false} style={{height:size}}>
														<button onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} className="zoomButton"><FullscreenOutlined /></button>

														<TileLayer
															attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
															url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
														/>
														{/* {view && view==='view' ? 
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        :
    
        <TileLayer
              url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
              maxZoom= {20}
              subdomains={['mt1','mt2','mt3']}
              
              />
              } */}
    
														{/* <TileLayer
          attribution='Stamen Watercolor'
          url="https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg"
        /> */}
    
														<LayersControl position="topright" >
															{oneviewType.toLowerCase() == 'well' ? 
																<>
																	<LayersControl.Overlay  checked name={langdata && langdata.WELL ? langdata.WELL :'WELL'}>
																		<MarkerClusterGroup>
			
																			{wells && wells.map((marker,index)=>
																				<Marker key={`well-${index}`} position={marker && marker.geocode} icon={wellicon}
																					eventHandlers={{
																						click: () => {
																							wellKey(marker);
																						},
																					}}>
																					<Popup>
																						{marker.pop}
																					</Popup>
																					{/* <Tooltip>{marker.pop}</Tooltip> */}
																				</Marker>
			
																			)}       
																		</MarkerClusterGroup>
			
																	</LayersControl.Overlay>

																	<MarkerClusterGroup>
																		{allPidsDatas && allPidsDatas.map((marker,index)=>
																			<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
																				<Popup>
																					{marker.pop}
																				</Popup>

																			</Marker>

	

																		)}       
																	</MarkerClusterGroup>

																	<MarkerClusterGroup>

																		{alarm_markers && alarm_markers.map((marker,index)=>
																			<Marker key={`alarm-${index}`} position={marker.geocode} icon={marker && marker ? marker.ic : defaulticon}>
																				<Popup>
																					{marker.pop}
																				</Popup>
																				{/* <Tooltip>{marker.pop}</Tooltip> */}
																			</Marker>

																		)}       
																	</MarkerClusterGroup>
																</>
																:
																<>
    
																	<MarkerClusterGroup>
																		{allPidsDatas && allPidsDatas.map((marker,index)=>
																			<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
																				<Popup>
																					{marker.pop}
																				</Popup>

																			</Marker>

	

																		)}       
																	</MarkerClusterGroup>
    
    
																	<LayersControl.Overlay  name={langdata && langdata.KMP ? langdata.KMP:'KMP'}>
																		<MarkerClusterGroup>
    
																			{KMP && KMP.map((marker,index)=>
																				<Marker key={`kmp-${index}`} position={marker.geocode} icon={KMPicon}>
																					<Popup>
																						{marker.pop}
																					</Popup>
																					{/* <Tooltip>{marker.pop}</Tooltip> */}
																				</Marker>
    
																			)}       
																		</MarkerClusterGroup>
    
																	</LayersControl.Overlay>
    
    
																	<LayersControl.Overlay  name={langdata && langdata.WB ? langdata.WB:'WB'}>
																		<MarkerClusterGroup>
    
																			{WB && WB.map((marker,index)=>
																				<Marker key={`wb-${index}`} position={marker.geocode} icon={WBicon}>
																					<Popup>
																						{marker.pop}
																					</Popup>
																					{/* <Tooltip>{marker.pop}</Tooltip> */}
																				</Marker>
    
																			)}       
																		</MarkerClusterGroup>
    
																	</LayersControl.Overlay>
    
    
    
    
																	<LayersControl.Overlay  name={langdata && langdata.TP ? langdata.TP:'TP'}>
																		<MarkerClusterGroup>
    
																			{Turn && Turn.map((marker,index)=>
																				<Marker key={`turn-${index}`} position={marker.geocode} icon={TPicon}>
																					<Popup>
																						{marker.pop}
																					</Popup>
																					{/* <Tooltip>{marker.pop}</Tooltip> */}
																				</Marker>
    
																			)}       
																		</MarkerClusterGroup>
    
																	</LayersControl.Overlay>
    
    
																	<LayersControl.Overlay  name={langdata && langdata.TLP ? langdata.TLP:'TLP'}>
																		<MarkerClusterGroup>
    
																			{TLP && TLP.map((marker,index)=>
																				<Marker key={`tlp-${index}`} position={marker.geocode} icon={TLPicon}>
																					<Popup>
																						{marker.pop}
																					</Popup>
																					{/* <Tooltip>{marker.pop}</Tooltip> */}
																				</Marker>
    
																			)}       
																		</MarkerClusterGroup>
    
																	</LayersControl.Overlay>
    
    
																	{/* <LayersControl.Overlay  name="POINTS">
										<MarkerClusterGroup>
    
											{points && points.slice(-100).map((marker,index)=>
												<Marker key={`points-${index}`} position={marker.geocode} icon={markericon}>
													<Popup>
														{marker.pop}
													</Popup>
													<Tooltip>{marker.pop}</Tooltip>
												</Marker>
    
											)}       
										</MarkerClusterGroup>
    
									</LayersControl.Overlay> */}
    
    
    
																	<LayersControl.Overlay name={langdata && langdata.CH ? langdata.CH:'CH'}>
																		<MarkerClusterGroup>
																			{markers && markers.map((marker,index)=>
																				<Marker key={`marker-${index}`} position={marker.geocode} icon={CHicon}>
																					<Popup>
																						{marker.pop}
																					</Popup>
																					{/* <Tooltip>{marker.pop}</Tooltip> */}
																				</Marker>
    
																			)}
    
																		</MarkerClusterGroup>
																	</LayersControl.Overlay>


																	<MarkerClusterGroup>

																		{alarm_markers && alarm_markers.map((marker,index)=>
																			<Marker key={`alarm-${index}`} position={marker.geocode} icon={marker && marker ? marker.ic : defaulticon}>
																				<Popup>
																					{marker.pop}
																				</Popup>
																				{/* <Tooltip>{marker.pop}</Tooltip> */}
																			</Marker>

																		)}       
																	</MarkerClusterGroup>
    
    
																	{/* <LayersControl.Overlay  checked name="LINE">
										<Polyline pathOptions={linecolor} weight={7} positions={line} />
									</LayersControl.Overlay> */}
    
    
																	<LayersControl.Overlay  checked name={oneviewtypeLabel ? oneviewtypeLabel:'Pipeline'}>
																		<MarkerClusterGroup>
																			<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
																				{tempMapArray && Object.values(tempMapArray).map((item) => {
																					let pipeline = [];
																					item.map((data) => {
																						pipeline.push({lat : data.latitude,lng : data.longitude});
																					});
																					return (
																						<>
																							<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
																						</>
																					);
																				})}
																			</Pane>
																		</MarkerClusterGroup>
																	</LayersControl.Overlay>


																	<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder:'Pipeline Border'}>
																		<MarkerClusterGroup>
																			{tempMapArray && Object.values(tempMapArray).map((item) => {
																				let pipelineBorder = [];
																				item.map((data) => {
																					pipelineBorder.push({lat : data.latitude,lng : data.longitude});
																				});
																				return (
																					<>
																						<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
																					</>
																				);
																			})}
																		</MarkerClusterGroup>
																	</LayersControl.Overlay>


																	<LayersControl.Overlay name={langdata && langdata.Fiber ? langdata.Fiber:'Fiber'}>
																		<MarkerClusterGroup>
																			{tempMapArray && Object.values(tempMapArray).map((item) => {
																				let odmeter = [];
																				item.map((data) => {
																					odmeter.push({lat : data.fibreLatitude,lng : data.fibreLongitude});
																				});
																				return (
																					<>
																						<Polyline pathOptions={odmetercolor} weight={10} positions={odmeter} />
																					</>
																				);
																			})}
																		</MarkerClusterGroup>
																	</LayersControl.Overlay>


																	<LayersControl.Overlay name={langdata && langdata.OptionalPath ? langdata.OptionalPath:'Optional Path'}>
																		<MarkerClusterGroup>
																			{teampOptionalMapArray && Object.values(teampOptionalMapArray).map((item) => {
																				let optionalpath = [];
																				item.map((data) => {
																					optionalpath.push({lat : data.walkingLatitude, lng : data.walkingLongitude});
																				});
																				return (
																					<>
																						<Polyline pathOptions={optionalcolor} weight={10} positions={optionalpath} />
																					</>
																				);
																			})}
																		</MarkerClusterGroup>
																	</LayersControl.Overlay>
																</>
															}
    
														</LayersControl>
													</MapContainer>
    
												</div>
												:null
										}


										{/* map view ends */}

										{/* Tab view starts */}

					


										<Tabs defaultActiveKey="1" onChange={callback}>
											<TabPane tab={langdata && langdata.AlarmDetails ? langdata.AlarmDetails:'Alarm Details'} key="1">
      
												<Row 
													style={{
														// margin: '10px',
														marginTop:'0px', paddingTop:'0px',
														// padding:'10px',
														background: '#FFFFFF',
												
													}}
												>
													<Col style={{marginTop:'0px', paddingTop:'0px', alignItems:'center',}}  span={12}>    
														<Typography style={{fontSize:'20px'}}>{langdata && langdata.AlarmDetails ? langdata.AlarmDetails:'Alarm Details'}</Typography>
														<br></br> 
														<Typography>{langdata && langdata.Id ? langdata.Id:'Id'}: {state.data && state.data && state.data.alarm_prm_id}</Typography>
														<Typography>{langdata && langdata.Activity ? langdata.Activity:'Activity'}: <b>{state.data && state.data && state.data.eventType}</b></Typography>
														<Typography>{langdata && langdata.Date ? langdata.Date:'Date'}: <b>{state.data && state.data && 
                moment.utc(state.data.datetime).local().format(settingsdateformat + ' ' + settingstimeformat)}</b>
														</Typography>
														<Typography>{langdata && langdata.Latitude ? langdata.Latitude:'Latitude'}: {state.data && state.data.locationDetails && state.data.locationDetails.Lat.toFixed(5)}</Typography>
														<Typography>{langdata && langdata.Longitude ? langdata.Longitude:'Longitude'}: {state.data && state.data.locationDetails && state.data.locationDetails.Long.toFixed(5)}</Typography>
														{/* <Typography>Region: {state.data && state.data && state.data.channel_name}</Typography>
												<Typography>PIDS: {state.data && state.data && state.data.channel_name}</Typography> */}
												
														<Typography>{langdata && langdata.Region ? langdata.Region:'Region'}: {state.data && state.data.regioninfoId && regiondata && regiondata.map((region) => { if (state.data.regioninfoId === region.id) { return region.regionName.toUpperCase();} })}</Typography>
														<Typography>{langdata && langdata.PIDS ? langdata.PIDS:'PIDS'}: {state.data && state.data.pidsinfoId && allPidsData && allPidsData.map((pids) => { if (state.data.pidsinfoId === pids.value){ return pids.label.toUpperCase();} }) }</Typography>
								
														<Typography>{langdata && langdata.Channel ? langdata.Channel:'Channel'}: {state.data && state.data && state.data.channel_name}</Typography>
														<Typography>{langdata && langdata.Chainage ? langdata.Chainage:'Chainage'}: {state.data && state.data.locationDetails && state.data.locationDetails.info}</Typography>
														<Typography>{langdata && langdata.Fiberlength ? langdata.Fiberlength:'Fiber Length'}: {state.data && state.data && state.data.odMeter}{' m'}</Typography>
														<Typography>{langdata && langdata.Duration ? langdata.Duration:'Duration'}: {state.data && state.data && (state.data.duration / 60).toFixed(2)} {' '}Min</Typography>
														<Typography style={{color:state.data.alarmSeverity === 0 ? 'CLEAR':
															state.data.alarmSeverity === 1 ? 'green':
																state.data.alarmSeverity === 2 ? '#fa9e0a' :
																	state.data.alarmSeverity === 3 ? 'red' : 
																		'black'}}>{langdata && langdata.Severity ? langdata.Severity:'Severity'}: {state.data && state.data && 
            (state.data.alarmSeverity === 0 ? 'CLEAR':state.data.alarmSeverity === 1 ? 'GREEN':state.data.alarmSeverity === 2 ? 'AMBER' :state.data.alarmSeverity === 3 ? 'RED' : '' )
															}</Typography>
														<Typography><b>{langdata && langdata.EventProbability ? langdata.EventProbability:'Event Probability'}: {state.data && state.data && ((state.data.eventprobablity)*100).toFixed(2)}%</b></Typography>
														<Typography>{langdata && langdata.Resolutionstatus ? langdata.Resolutionstatus:'Resolution status'}: <b>{alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].status}</b></Typography>
														<Typography>{langdata && langdata.Remark ? langdata.Remark:'Remark'}: {alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].remarks}</Typography>
													</Col>

													<Col style={{marginTop:'0px', paddingTop:'0px', alignItems:'center',}}  span={12}>  
														{alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].status == 'New' || alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].status == 'In Inspection'  ?
															<Col style={{justifyContent:'right'}} span={24}>
																<Row  style={{justifyContent:'right', paddingRight:'30px'}}>    
																	<Space>      
																		<Button style={{margin:'5px'}} onClick={handleAttachmentModelOpen} type='primary'> {langdata && langdata.ADDACKNOWLEDGEMENT ? langdata.ADDACKNOWLEDGEMENT:'ADD ACKNOWLEDGEMENT'}</Button> 
																	</Space>
																	<Space>&nbsp;</Space>
																	<Space> 
																		<Button style={{margin:'5px'}} onClick={handleResModelOpen} type='primary'>{langdata && langdata.ADDRESOLUTION ? langdata.ADDRESOLUTION:'ADD RESOLUTION'}</Button>
																	</Space>
																	<Space>&nbsp;</Space>
																</Row>
															</Col>
															:
															null
														}

														{alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].status == 'Acknowledged' ?
															<Col style={{justifyContent:'right'}} span={24}>
																<Row  style={{justifyContent:'left', paddingRight:'30px'}}>   
																	<Col span={12}>
																		<Typography style={{fontSize:'20px'}}>{langdata && langdata.AcknowledgementDetails ? langdata.AcknowledgementDetails:'Acknowledgement Details'}</Typography>
																		{/* <Typography>Id: {alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackdetDetails.id}</Typography> */}
																		<Typography>{langdata && langdata.AcknowledgedActivity ? langdata.AcknowledgedActivity:'Acknowledged Activity'}: {alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackdetDetails.predictedEvent}</Typography>
																		<Typography>{langdata && langdata.AcknowledgedDateTime ? langdata.AcknowledgedDateTime:'Acknowledged Date & Time'}: {' '}
																			
																			{state.data && state.data && 
																					moment(moment.utc(alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackdetDetails.createdAt).toDate(), 'YYYY-MM-DD HH:mm:ss').format(settingsdateformat + ' ' + settingstimeformat)}
																		
																		</Typography>
																		<Typography>{langdata && langdata.AcknowledgedBy ? langdata.AcknowledgedBy:'Acknowledged By'}: {alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackuser}</Typography>
																		<Typography>{langdata && langdata.Remark ? langdata.Remark:'Remark'}: {alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackdetDetails.remarks}</Typography>
																		<br></br>
																		<br></br>
																		<Button type="primary" title={alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackdetDetails.imageUrl && alarmdata.relatimealarmdata[0].ackdetDetails.imageUrl.length >0 ? 'Show attachments' : 'No attachments found' }  disabled={alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackdetDetails.imageUrl && alarmdata.relatimealarmdata[0].ackdetDetails.imageUrl.length >0 ? false : true} onClick={openShowAttachments}>{langdata && langdata.SHOWATTACHMENTS ? langdata.SHOWATTACHMENTS:'SHOW ATTACHMENTS'}</Button>
																	</Col>
																	<Col span={12}>
																		<Space> 
																			<Button style={{margin:'5px'}} onClick={handleResModelOpen} type='primary'>{langdata && langdata.ADDRESOLUTION ? langdata.ADDRESOLUTION:'ADD RESOLUTION'}</Button>
																		</Space>
																	</Col>
																</Row>
															</Col>	
															:
															null
														}

														{alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].status == 'Resolved' ?
															<Col style={{justifyContent:'right'}} span={24}>
																<Row  style={{justifyContent:'left', paddingRight:'30px'}}>  
																	<Col>
																		<Typography style={{fontSize:'20px'}}>{langdata && langdata.ResolutionDetails ? langdata.ResolutionDetails:'Resolution Details'}</Typography>
																		<br></br>
																		{alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].resolutionDetails.isEnabledForRetraining ? 
																			<Typography>{langdata && langdata.MarkedForRetrainingDate ? langdata.MarkedForRetrainingDate:'Marked For Retraining Date'}:{' '}
																				{
																					moment(moment.utc(alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].resolutionDetails.reTrainingDate).toDate(), 'YYYY-MM-DD HH:mm:ss').format(settingsdateformat + ' ' + settingstimeformat)}
																			</Typography> : null}
																		<Typography>{langdata && langdata.ReportedEvent ? langdata.ReportedEvent:'Reported Event'}: {alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].resolutionDetails.predictedEvent}</Typography>
																		<Typography>{langdata && langdata.ActualEvent ? langdata.ActualEvent:'Actual Event'}: {alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].eventType}</Typography>
																		<Typography>{langdata && langdata.Resolutionstatus ? langdata.Resolutionstatus:'Resolution status'}: {alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].status}</Typography>
																		<Typography>{langdata && langdata.ResolutionAddedby ? langdata.ResolutionAddedby:'Resolution Added by'}: {alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].resolutionuser}</Typography>
																		<Typography>{langdata && langdata.ResolutionDateTime ? langdata.ResolutionDateTime:'Resolution Date & Time'}: {
																			moment(moment.utc(alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].resolutionDetails.createdAt).toDate(), 'YYYY-MM-DD HH:mm:ss').format(settingsdateformat + ' ' + settingstimeformat)}
																		</Typography>
																		<Typography>{langdata && langdata.Remark ? langdata.Remark:'Remark'}: {alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].remarks}</Typography>
																		{/* <Typography>ACK Remark: {alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackdetDetails.remarks}</Typography> */}
																		<br></br>
																		{alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].resolutionDetails ?
																			<>
																				<Button type="primary" title={alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].resolutionDetails.imageUrl && alarmdata.relatimealarmdata[0].resolutionDetails.imageUrl.length >0 ? 'Show attachments' : 'No attachments found' } disabled={alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].resolutionDetails.imageUrl && alarmdata.relatimealarmdata[0].resolutionDetails.imageUrl.length >0 ? false : true} onClick={openShowResAttachments}>SHOW ATTACHMENTS</Button>
																				<br></br>
																				<br></br>
																			</>
																			: null
																		}
																	</Col>
																</Row>

																{alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackdetDetails ? 
																	<><Row style={{ justifyContent: 'left', paddingRight: '30px' }}>
																		<Col>
																			<br></br>
																		</Col>
																	</Row><Row style={{ justifyContent: 'left', paddingRight: '30px' }}>
																		<Col>
																			<Typography style={{ fontSize: '20px' }}>{langdata && langdata.AcknowledgementDetails ? langdata.AcknowledgementDetails:'Acknowledgement Details'}</Typography>
																			<br></br>
																			{/* <Typography>Id: {alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackdetDetails.id}</Typography> */}
																			<Typography>{langdata && langdata.AcknowledgedActivity ? langdata.AcknowledgedActivity:'Acknowledged Activity'}: {alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackdetDetails.predictedEvent}</Typography>
																			<Typography>{langdata && langdata.AcknowledgedDateTime ? langdata.AcknowledgedDateTime:'Acknowledged Date & Time'}: {' '}

																				{state.data && state.data &&
																				moment(moment.utc(alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackdetDetails.createdAt).toDate(), 'YYYY-MM-DD HH:mm:ss').format(settingsdateformat + ' ' + settingstimeformat)}

																			</Typography>
																			<Typography>{langdata && langdata.AcknowledgedBy ? langdata.AcknowledgedBy:'Acknowledged By'}: {alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackuser}</Typography>
																			<Typography>{langdata && langdata.Remark ? langdata.Remark:'Remark'}: {alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackdetDetails.remarks}</Typography>
																			<br></br>
																			<Button type="primary" title={alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackdetDetails.imageUrl && alarmdata.relatimealarmdata[0].ackdetDetails.imageUrl.length >0 ? 'Show attachments' : 'No attachments found' } disabled={alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackdetDetails.imageUrl && alarmdata.relatimealarmdata[0].ackdetDetails.imageUrl.length >0 ? false : true} onClick={openShowAttachments}>{langdata && langdata.SHOWATTACHMENTS ? langdata.SHOWATTACHMENTS:'SHOW ATTACHMENTS'}</Button>
																		</Col>
																	</Row></>
																	: null
																}
															</Col>
															:
															null
														}



													</Col>
												</Row> 
												<Row style={{justifyContent:'left', display:'flex'}} 
													span={24}>
													<Col style={{justifyContent:'left', display:'flex'}} span={12}> 
														<img src={state && state.data.activityUrl} alt={`${state && state.data.eventType}`} align="right" />
													</Col>
													{/* 											
													<Col style={{justifyContent:'left', display:'flex'}}   span={12}>
														{state.data && state.data && state.data.eventType === 'Manual Digging' ? 
															<img src={mapImg} alt="manual digging" align="right" /> : 
															state.data && state.data && state.data.eventType === 'Machine Digging' ? 
																<img src={mapImgMachine} alt="Machine" align="right" /> : 
																state.data && state.data && state.data.eventType === 'Tunneling' ? 
																	<img src={mapImgTunneling} alt="Tunneling" align="right" /> : 
																	state.data && state.data && state.data.eventType === 'Vehicle Movement' ? 
																		<img src={VehicleModal} alt="Tunneling" align="right" /> : 
																		state.data && state.data && state.data.eventType === 'Drums Beating' ? 
																			<img src={DrumModal} alt="Tunneling" align="right" /> : 
																			state.data && state.data && state.data.eventType === 'Clamping' ? 
																				<img src={ClampingModal} alt="Tunneling" align="right" /> : 
																				state.data && state.data && state.data.eventType === 'Manual Digging - Deeper (1.5 to 3 feets)' ? 
																					<img width="300px" src={mapImgDeep} alt="Manual Digging - Deeper (1.5 to 3 feets)" align="right" /> : 
																					state.data && state.data && state.data.eventType === 'Man Movement' ? 
																						<img src={modalMan} alt="Man Movement" align="right" /> :
																						<img src={modalDefault} alt="Unclassified" align="right" /> }
													</Col>  */}
													<Col span={12}></Col>
												</Row>   
											</TabPane>

  

											<TabPane tab={langdata && langdata.Comments ? langdata.Comments:'Comments'} key="2">
												<Row>
													<Col span={24}>
														<Row style={{display:'flex', justifyContent:'right'}}>
															<Button onClick={handleOpenCommentBox} type='primary'>{langdata && langdata.ADDCOMMENT ? langdata.ADDCOMMENT:'ADD COMMENT'}</Button>

														</Row>
													</Col>


												</Row>

												<List
													itemLayout="horizontal"
													dataSource={commentdetails}
													renderItem={(item) => (
														<List.Item>
															<List.Item.Meta
																avatar={<Avatar style={{
																	backgroundColor: 'orange',
																	verticalAlign: 'middle',
															
																}}
																size="large"
																>{item.title[0]}</Avatar>}
																description={item.title}
																title={item.comments}
														
															>
															</List.Item.Meta>
														</List.Item>
													)}
												/>
    
											</TabPane>

											<TabPane tab={langdata && langdata.RelatedAlarms ? langdata.RelatedAlarms:'Related Alarms'} key="3">
												<Row span={24} style={{
													margin: '10px',
													paddingLeft: '10px',
													paddingRight: '10px',
													background: '#FFFFFF',
													alignItems:'center'
												}} >
													<Col span={24} style={{ margin : '10px'}}>
														<Table 
															scroll={{
																x: 900,
															}}
															columns={columns} dataSource={relatedAlarmList} />
													</Col>
												</Row>
											</TabPane>

											<TabPane onClick={()=>{
											}} tab={langdata && langdata.GroupedAlarms ? langdata.GroupedAlarms:'Grouped Alarms'} key="4">
												<Row span={24} style={{
													margin: '10px',
													paddingLeft: '10px',
													paddingRight: '10px',
													background: '#FFFFFF',
													alignItems:'center'
												}} >
													<Col span={24} style={{ margin : '10px'}}>
														<Table 
															scroll={{
																x: 900,
															}}
															columns={columns} dataSource={groupedAlarmList} />
													</Col>
												</Row>
											</TabPane>

											<TabPane tab={langdata && langdata.RegionHistory ? langdata.RegionHistory:'Region History'} key="5"><Row span={24} style={{
												margin: '10px',
												paddingLeft: '10px',
												paddingRight: '10px',
												background: '#FFFFFF',
												alignItems:'center'
											}} >
												<Col span={24} style={{ textAlign: 'center', justifyContent:'center', display:'flex' }}>
													<Tag color="blue" style={{ marginBottom: '10px', textAlign: 'center', justifyContent:'center', display:'flex' }}>
														{langdata && langdata.Youareviewingalarmsinthisregionsincelast3months ? langdata.Youareviewingalarmsinthisregionsincelast3months:'*You are viewing alarms in this region since last 3 months'}
													</Tag>
												</Col>
												<Col span={24} style={{ margin : '10px'}}>
													<Table 
														scroll={{
															x: 900,
														}}
														columns={columns} dataSource={regionAlarmList} />
												</Col>
											</Row>
											</TabPane>
											{dashboardtype==='tlp' || ( dashboardtype==='oneview' && tlpEnable === 'enabled') ?
												<TabPane tab={langdata && langdata.TLPDetails ? langdata.TLPDetails:'TLP Details'} key="6" >
													<TLPLogs pipelineConfigId={state.data && state.data.pipelineConfigId} />
												</TabPane>
												:
												null
											}

									


										</Tabs>
									</Card>
								</Col>

							</Row>

							{/* attachments modal starts */}


      
      

							<Modal
								open={modelAttachmentOpen}
								title={langdata && langdata.AddAcknowledgement ? langdata.AddAcknowledgement:'Add Acknowledgement'}
								onOk={handleModelSave}
								onCancel={handleModelCancel}
								footer={[
									// eslint-disable-next-line react/jsx-key
									<Button onClick={handleModelCancel}>
										{langdata && langdata.Cancel ? langdata.Cancel:'Cancel'}
									</Button>,
									// eslint-disable-next-line react/jsx-key
									<Button onClick={handleModelSave} type="primary">
										{langdata && langdata.Save ? langdata.Save:'Save'}
									</Button>,
								]}
							>
								<Divider/>
								<Row
									gutter={{
										xs: 8,
										sm: 16,
										md: 24,
										lg: 32,
									}}
								>

									<Col style={{margin:'10px'}} span={24}>         


										<Radio.Group onChange={onAckChange} value={ackvalue}>
											<Space direction="vertical">
												<Radio value={1}>{langdata && langdata.Inspectedactivityissameasreported ? langdata.Inspectedactivityissameasreported:'Inspected activity is same as reported'}</Radio>
												<Radio value={2}>{langdata && langdata.Inspectedactivityisdifferentfromreported ? langdata.Inspectedactivityisdifferentfromreported:'Inspected activity is different from reported'}</Radio>
											</Space>
										</Radio.Group>
									</Col>
									<Col span={24}>
										{ackvalue === 2 ? (
											<div style={{ marginTop: '10px' }}>
												<div style={{ textAlign: 'left', marginLeft: '22%', width: '100%' }}>
													<Radio.Group onChange={onChangeEvent}>
														{events}
														<Radio style={radioStyle} value={'others'}>
															{langdata && langdata.Others ? langdata.Others:'Others'}
															{showOtherOpt && (
																<Select
																	// onChange={this.commentChange}
																	style={{ width: '80%', marginLeft: 10 }}
																>
																	<Option value="Sheep/Cattle Grazing">{langdata && langdata.SheepCattleGrazing ? langdata.SheepCattleGrazing:'Sheep/Cattle Grazing'}</Option>
																	<Option value="Horse/Cow/Buffalo/Goat tied inside ROU">
																		{langdata && langdata.HorseCowBuffaloGoattiedinsideROU ? langdata.HorseCowBuffaloGoattiedinsideROU:'Horse/Cow/Buffalo/Goat tied inside ROU'}
																	</Option>
																	<Option value="Nala flowing inside ROU">
																		{langdata && langdata.NalaflowinginsideROU ? langdata.NalaflowinginsideROU:'Nala flowing inside ROU'}
																	</Option>
																	<Option value="Ploughing activity">{langdata && langdata.Ploughingactivity ? langdata.Ploughingactivity:'Ploughing activity'}</Option>
																	<Option value="Grass cutting">{langdata && langdata.Grasscutting ? langdata.Grasscutting:'Grass cutting'}</Option>
																	<Option value="No activity found">{langdata && langdata.Noactivityfound ? langdata.Noactivityfound:'No activity found'}</Option>
																</Select>
															)}
														</Radio>
													</Radio.Group>
												</div>
											</div>
										) : null}
										<br />
									</Col>
									<Col span={24}> 
										{langdata && langdata.Remarks ? langdata.Remarks:'Remarks'}:

										<br />

										<TextArea style={{marginTop:'10px'}} value={ackRemarks}  onChange={handleAckComment} rows={4} placeholder={langdata && langdata.MaxLengthis ? langdata.MaxLengthis:'Max Length is 500'} maxLength={500} />

									</Col>
									<Col style={{margin:'10px'}} span={24}>  
										{/* <ImgCrop rotationSlider>
									<Upload
										action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
										listType="picture-card"
										fileList={fileList}
										// onChange={onChangeImage}
										onPreview={onPreview}
									>
										{fileList.length < 5 && '+ Upload'}
									</Upload>
								</ImgCrop>  */}
										<Button >
								
											<input onChange={onChangeImage} id='fileUpload' type='file' multiple  
												accept="image/*"
											/>
										</Button>
									</Col>
								</Row>
							</Modal>


							{/* attachments modal ends */}


							{/* resolution modal starts */}

							<Modal
								open={modelResolutionOpen}
								title={langdata && langdata.AddResolution ? langdata.AddResolution:'Add Resolution'}
								onOk={handleResModelSave}
								onCancel={handleResModelCancel}
								footer={[
									// eslint-disable-next-line react/jsx-key
									<Button onClick={handleResModelCancel}>
										{langdata && langdata.Cancel ? langdata.Cancel:'Cancel'}
									</Button>,
									// eslint-disable-next-line react/jsx-key
									<Button onClick={handleResModelSave} type="primary">
										{langdata && langdata.Save ? langdata.Save:'Save'}
									</Button>,
								]}
							>
								<Divider/>
								<Row
									gutter={{
										xs: 8,
										sm: 16,
										md: 24,
										lg: 32,
									}}
								>

									<Col style={{margin:'10px'}} span={24}>         


										<Radio.Group onChange={onResChange} value={resvalue}>
											<Space direction="vertical">
												<Radio value={1}>{langdata && langdata.Inspectedactivityissameasreported ? langdata.Inspectedactivityissameasreported:'Inspected activity is same as reported'}</Radio>
												<Radio value={2}>{langdata && langdata.Inspectedactivityisdifferentfromreported ? langdata.Inspectedactivityisdifferentfromreported:'Inspected activity is different from reported'}</Radio>
											</Space>
										</Radio.Group>
									</Col>

									<Col span={24}>
										{resvalue === 2 ? (
											<div style={{ marginTop: '10px' }}>
												<div style={{ textAlign: 'left', marginLeft: '22%', width: '100%' }}>
													<Radio.Group onChange={onChangeEvent}>
														{events}
														<Radio style={radioStyle} value={'others'}>
															{langdata && langdata.Others ? langdata.Others:'Others'}
															{showOtherOpt && (
																<Select
																	// onChange={this.commentChange}
																	style={{ width: '80%', marginLeft: 10 }}
																>
																	<Option value="Sheep/Cattle Grazing">{langdata && langdata.SheepCattleGrazing ? langdata.SheepCattleGrazing:'Sheep/Cattle Grazing'}</Option>
																	<Option value="Horse/Cow/Buffalo/Goat tied inside ROU">
																		{langdata && langdata.HorseCowBuffaloGoattiedinsideROU ? langdata.HorseCowBuffaloGoattiedinsideROU:'Horse/Cow/Buffalo/Goat tied inside ROU'}
																	</Option>
																	<Option value="Nala flowing inside ROU">
																		{langdata && langdata.NalaflowinginsideROU ? langdata.NalaflowinginsideROU:'Nala flowing inside ROU'}
																	</Option>
																	<Option value="Ploughing activity">{langdata && langdata.Ploughingactivity ? langdata.Ploughingactivity:'Ploughing activity'}</Option>
																	<Option value="Grass cutting">{langdata && langdata.Grasscutting ? langdata.Grasscutting:'Grass cutting'}</Option>
																	<Option value="No activity found">{langdata && langdata.Noactivityfound ? langdata.Noactivityfound:'No activity found'}</Option>
																</Select>
															)}
														</Radio>
													</Radio.Group>
												</div>
											</div>
										) : null}
										<br />
									</Col>


									<Col span={24}> 
										{langdata && langdata.Remarks ? langdata.Remarks:'Remarks'}:

										<br />

										<TextArea style={{marginTop:'10px'}} value={resRemarks}  onChange={handleResComment} rows={4} placeholder="Max Length is 500" maxLength={500} />

									</Col>
									<Col style={{margin:'10px'}} span={24}>  
										{/* <ImgCrop rotationSlider>
									<Upload
										action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
										listType="picture-card"
										fileList={fileList}
										// onChange={onChangeImage}
										onPreview={onPreview}
									>
										{fileList.length < 5 && '+ Upload'}
									</Upload>
								</ImgCrop>  */}
										<Button >
								
											<input onChange={onChangeImage} id='fileUpload' type='file' multiple  
												accept="image/*"
											/>
										</Button>
									</Col>
									<Col title={daysDifference < 2 ? '' : langdata && langdata.Resolutionisavailableonlyforlast48hours ? langdata.Resolutionisavailableonlyforlast48hours: 'Resolution is available only for last 48 hours'} style={{margin:'10px'}} span={24}>  
										<Checkbox disabled={daysDifference < 2 ? false : true} onChange={onCheck}>{langdata && langdata.Savetheactivityforretraining ? langdata.Savetheactivityforretraining:'Save the activity for retraining'}</Checkbox>
									</Col>
								</Row>
							</Modal>


							{/* resolution modal end */}

							{/* Fetch and Download Modal Starts */}
							<Modal
								title={langdata && langdata.Attachments ? langdata.Attachments:'Attachments'}
								centered
								destroyOnClose={true}
								open={openAttachmentModal}
								onOk={{closeShowAttachments}}
								onCancel={closeShowAttachments}
								footer={[
								]}
								width={540}
							>
								{alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].ackdetDetails && alarmdata.relatimealarmdata[0].ackdetDetails.imageUrl.length > 0  ? 

									alarmdata.relatimealarmdata[0].ackdetDetails.imageUrl.map((item) => (
										<div key={item.id} style={{ paddingTop: '20px' }}>
											
											<>
												<img style={{width:'100%', height:'360px'}} src={item.trim()} alt='image' />
												<a target="_blank" href={item.trim()} onClick={() => {
													handleDownload(item.trim());
												}} rel="noreferrer" >
													<br/> 
													<DownloadOutlined style={{fontSize:'20px'}}/> {langdata && langdata.Downloadfile ? langdata.Downloadfile:'Download file'}</a>
											</>
												
										</div>
									))        
        
									: (
								// console.log('No data'),
										<>
											<Row gutter={16} style={{
												backgroundColor: '#d3d3d3',
												padding: '5px',
												marginTop: '10px'
											}}>
												<Col span={24}>
													<Typography>{langdata && langdata.Noattachmentsfound ? langdata.Noattachmentsfound:'No attachments found.'}</Typography>
												</Col>
											</Row>
											<br/>
										</>
          
									)}
							</Modal>
							{/* Resolution attachments modal */}
							<Modal
								title={langdata && langdata.Attachments ? langdata.Attachments:'Attachments'}
								centered
								destroyOnClose={true}
								open={openResAttachmentModal}
								onOk={{closeShowAttachments}}
								onCancel={closeShowAttachments}
								footer={[
								]}
								width={540}
							>
								{alarmdata.relatimealarmdata && alarmdata.relatimealarmdata[0].resolutionDetails && alarmdata.relatimealarmdata[0].resolutionDetails.imageUrl && alarmdata.relatimealarmdata[0].resolutionDetails.imageUrl.length > 0  ? 

									alarmdata.relatimealarmdata[0].resolutionDetails.imageUrl.map((item) => (
										<div key={item.id} style={{ paddingTop: '20px' }}>
											<>
												<img style={{width:'100%', height:'360px'}} src={item.trim()} alt='image' />
												<a target="_blank" href={item.trim()} onClick={() => {
													handleDownload(item.trim());
												}} rel="noreferrer" >
													<br/> 
													<DownloadOutlined style={{fontSize:'20px'}}/>{langdata && langdata.Downloadfile ? langdata.Downloadfile:'Download file'}</a>
											</>
										</div>
									))        
        
									: (
								// console.log('No data'),
										<>
											<Row gutter={16} style={{
												backgroundColor: '#d3d3d3',
												padding: '5px',
												marginTop: '10px'
											}}>
												<Col span={24}>
													<Typography>{langdata && langdata.Noattachmentsfound ? langdata.Noattachmentsfound:'No attachments found.'}</Typography>
												</Col>
											</Row>
											<br/>
										</>
          
									)}
							</Modal>
							{/* Fetch and Download Modal Modal Ends */}
							<Modal
								title={langdata && langdata.AddComments ? langdata.AddComments:'Add Comments'}
								centered
								destroyOnClose={true}
								open={openCommentModal}
								onOk={handleCommentSubmit}
								onCancel={handleCloseCommentBox}
								width={540}
								footer={[
									// eslint-disable-next-line react/jsx-key
									<Button onClick={handleCommentSubmit} type="primary">
										{langdata && langdata.Save ? langdata.Save:'Save'}
									</Button>,
									// eslint-disable-next-line react/jsx-key
									<Button type="primary" onClick={handleCloseCommentBox}>
										{langdata && langdata.Cancel ? langdata.Cancel:'Cancel'}
									</Button>,
								]}
							>
						
								<br />

								<TextArea value={comments && comments}  onChange={handleComment} rows={4} placeholder={langdata && langdata.MaxLengthis ? langdata.MaxLengthis:'Max Length is 500'} maxLength={500} />

						
							</Modal>

							<Spin fullscreen spinning={alarmdata.pending} />

				

						</Content>
          
          
          
          
						: 
						<div className="App" style={{textAlign: 'left', margin : '10px'}}>
							<MapContainer center={center} zoom={17} scrollWheelZoom={false}>
								<button onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} className="zoomButton"><FullscreenExitOutlined /></button>

								<TileLayer
									attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								/>
								{/* {view && view==='view' ? 
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    :

    <TileLayer
          url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
          
          />
          } */}

								{/* <TileLayer
      attribution='Stamen Watercolor'
      url="https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg"
    /> */}

								<LayersControl position="topright" >
									{oneviewType.toLowerCase() == 'well' ? 
										<>
											<LayersControl.Overlay  checked name={langdata && langdata.WELL ? langdata.WELL :'WELL'}>
												<MarkerClusterGroup>
			
													{wells && wells.map((marker,index)=>
														<Marker key={`well-${index}`} position={marker && marker.geocode} icon={wellicon}
															eventHandlers={{
																click: () => {
																	wellKey(marker);
																},
															}}>
															<Popup>
																{marker.pop}
															</Popup>
															{/* <Tooltip>{marker.pop}</Tooltip> */}
														</Marker>
			
													)}       
												</MarkerClusterGroup>
			
											</LayersControl.Overlay>

											<MarkerClusterGroup>
												{allPidsDatas && allPidsDatas.map((marker,index)=>
													<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
														<Popup>
															{marker.pop}
														</Popup>

													</Marker>

	

												)}       
											</MarkerClusterGroup>

											<MarkerClusterGroup>

												{alarm_markers && alarm_markers.map((marker,index)=>
													<Marker key={`alarm-${index}`} position={marker.geocode} icon={marker && marker ? marker.ic : defaulticon}>
														<Popup>
															{marker.pop}
														</Popup>
														{/* <Tooltip>{marker.pop}</Tooltip> */}
													</Marker>

												)}       
											</MarkerClusterGroup>
										</>
										:
										<>

											<MarkerClusterGroup>
												{allPidsDatas && allPidsDatas.map((marker,index)=>
													<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
														<Popup>
															{marker.pop}
														</Popup>

													</Marker>

	

												)}       
											</MarkerClusterGroup>


											<LayersControl.Overlay  name={langdata && langdata.KMP ? langdata.KMP:'KMP'}>
												<MarkerClusterGroup>

													{KMP && KMP.map((marker,index)=>
														<Marker key={`kmp-${index}`} position={marker.geocode} icon={KMPicon}>
															<Popup>
																{marker.pop}
															</Popup>
															{/* <Tooltip>{marker.pop}</Tooltip> */}
														</Marker>

													)}       
												</MarkerClusterGroup>

											</LayersControl.Overlay>

    
											<LayersControl.Overlay  name={langdata && langdata.WB ? langdata.WB:'WB'}>
												<MarkerClusterGroup>

													{WB && WB.map((marker,index)=>
														<Marker key={`wb-${index}`} position={marker.geocode} icon={WBicon}>
															<Popup>
																{marker.pop}
															</Popup>
															{/* <Tooltip>{marker.pop}</Tooltip> */}
														</Marker>

													)}       
												</MarkerClusterGroup>

											</LayersControl.Overlay>




											<LayersControl.Overlay  name={langdata && langdata.TP ? langdata.TP:'TP'}>
												<MarkerClusterGroup>

													{Turn && Turn.map((marker,index)=>
														<Marker key={`turn-${index}`} position={marker.geocode} icon={TPicon}>
															<Popup>
																{marker.pop}
															</Popup>
															{/* <Tooltip>{marker.pop}</Tooltip> */}
														</Marker>

													)}       
												</MarkerClusterGroup>

											</LayersControl.Overlay>

  
											<LayersControl.Overlay  name={langdata && langdata.TLP ? langdata.TLP:'TLP'}>
												<MarkerClusterGroup>

													{TLP && TLP.map((marker,index)=>
														<Marker key={`tlp-${index}`} position={marker.geocode} icon={TLPicon}>
															<Popup>
																{marker.pop}
															</Popup>
															{/* <Tooltip>{marker.pop}</Tooltip> */}
														</Marker>

													)}       
												</MarkerClusterGroup>

											</LayersControl.Overlay>


											{/* <LayersControl.Overlay  name="POINTS">
								<MarkerClusterGroup>

									{points && points.map((marker,index)=>
										<Marker key={`points-${index}`} position={marker.geocode} icon={markericon}>
											<Popup>
												{marker.pop}
											</Popup>
											<Tooltip>{marker.pop}</Tooltip>
										</Marker>

									)}       
								</MarkerClusterGroup>

							</LayersControl.Overlay> */}



											<LayersControl.Overlay name={langdata && langdata.CH ? langdata.CH:'CH'}>
												<MarkerClusterGroup>
													{markers && markers.map((marker,index)=>
														<Marker key={`marker-${index}`} position={marker.geocode} icon={CHicon}>
															<Popup>
																{marker.pop}
															</Popup>
															{/* <Tooltip>{marker.pop}</Tooltip> */}
														</Marker>

													)}

												</MarkerClusterGroup>
											</LayersControl.Overlay>


											<MarkerClusterGroup>

												{alarm_markers && alarm_markers.map((marker,index)=>
													<Marker key={`alarm-${index}`} position={marker.geocode} icon={marker && marker ? marker.ic : defaulticon}>
														<Popup>
															{marker.pop}
														</Popup>
														{/* <Tooltip>{marker.pop}</Tooltip> */}
													</Marker>

												)}       
											</MarkerClusterGroup>

       
											{/* <LayersControl.Overlay  checked name="LINE">
								<Polyline pathOptions={linecolor} weight={7} positions={line} />
							</LayersControl.Overlay> */}

      
											<LayersControl.Overlay  checked name={oneviewtypeLabel ? oneviewtypeLabel:'Pipeline'}>
												<MarkerClusterGroup>
													<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
														{tempMapArray && Object.values(tempMapArray).map((item) => {
															let pipeline = [];
															item.map((data) => {
																pipeline.push({lat : data.latitude,lng : data.longitude});
															});
															return (
																<>
																	<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
																</>
															);
														})}
													</Pane>
												</MarkerClusterGroup>
											</LayersControl.Overlay>


											<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder:'Pipeline Border'}>
												<MarkerClusterGroup>
													{tempMapArray && Object.values(tempMapArray).map((item) => {
														let pipelineBorder = [];
														item.map((data) => {
															pipelineBorder.push({lat : data.latitude,lng : data.longitude});
														});
														return (
															<>
																<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
															</>
														);
													})}
												</MarkerClusterGroup>
											</LayersControl.Overlay>


											<LayersControl.Overlay name={langdata && langdata.Fiber ? langdata.Fiber:'Fiber'}>
												<MarkerClusterGroup>
													{tempMapArray && Object.values(tempMapArray).map((item) => {
														let odmeter = [];
														item.map((data) => {
															odmeter.push({lat : data.fibreLatitude,lng : data.fibreLongitude});
														});
														return (
															<>
																<Polyline pathOptions={odmetercolor} weight={10} positions={odmeter} />
															</>
														);
													})}
												</MarkerClusterGroup>
											</LayersControl.Overlay>


											<LayersControl.Overlay name={langdata && langdata.OptionalPath ? langdata.OptionalPath:'Optional Path'}>
												<MarkerClusterGroup>
													{teampOptionalMapArray && Object.values(teampOptionalMapArray).map((item) => {
														let optionalpath = [];
														item.map((data) => {
															optionalpath.push({lat : data.walkingLatitude, lng : data.walkingLongitude});
														});
														return (
															<>
																<Polyline pathOptions={optionalcolor} weight={10} positions={optionalpath} />
															</>
														);
													})}
												</MarkerClusterGroup>
											</LayersControl.Overlay>
										</>
									}

								</LayersControl>
							</MapContainer>

						</div>
        
        
					}
				</>
				: '' }
			{/* well modal start */}

			<Modal
				open={wellModal}
				title={langdata && langdata.Well3d ? langdata.Well3d :'3d Well'}
				onOk={handleModelCancel}
				onCancel={handleModelCancel}
				footer={[
					// eslint-disable-next-line react/jsx-key
					// <Button type="primary" onClick={handleModelCancel}>
					// 	{langdata && langdata.Close ? langdata.Close :'Close'}
					// </Button>
				]}
				style={{backgroundColor:'transparent'}}
				width={800}
			>
				<Divider/>

				<Row
					gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}>
					<Col span={24} style={{ minHeight :'450px', display : 'flex', justifyContent : 'center' }}>          
									
						{/* <Card style={{width:'100%', height : '100%'}}> */}
			
						{/* {data3d && data3d && data3d.length > 0 ?
										
			<> */}
						<Spin  spinning={meshSpin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
						
							{/* <button style={{marginTop:'10px', marginRight:'5px'}}
															onClick={()=>{handlefullSize(fullsize === false ? true:false,'3dwell');}} 
															className="graphZoomButton" ><FullscreenOutlined /></button> */}
							{(Object.keys(meshData).length !== 0) ?
								<Plot
									style={{ width: '750px', height: '600px',minHeight:'450px' }}
									config={{
										responsive: true,
										// displayModeBar: true,
										displaylogo : false,
										modeBarButtonsToRemove: [
											'pan2d',
											'zoom2d',
											'select2d',
											'zoomIn2d',
											'zoomOut2d',
											'autoScale2d',
											'resetScale2d',
											'hoverClosestCartesian',
											'hoverCompareCartesian',
											'zoom3d',
											'pan3d',
											'resetViews3d',
											'hoverClosest3d',
											'hoverCompare3d',
											'orbitRotation',
											'tableRotation',
											'resetCameraDefault3d',
											'resetCameraLastSave3d',
											'lasso2d'
										],
			
									}}
									data={wellData}
									layout={wellLayout}
								/>
								: 
														
								<Typography>{langdata && langdata.NoData ? langdata.NoData : 'No Data'}</Typography>
							}
						</Spin>
						{/* </>
			:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '500px', minHeight:'500px', backgroundColor:'#FFFFFF' }} />} */}
			
			
						{/* </Card> */}
			
					</Col>
							
				</Row>
			</Modal>

			{/* well modal end */}
		</>
	);
}